import firebase from "firebase";
import "firebase/database";
import _ from "lodash";
// import Store from "../../MobX/Store";
const GetGroupChats = async (cid, uid, setGroupChats) => {
  let returnedValue = null;
  //   await Promise.all([
  firebase
    .database()
    .ref("chats")
    .child(`group/${cid}`)
    .orderByChild(uid)
    .equalTo(true)
    .on("value", async (q) => {
      if (q.exists()) {
        let keys = Object.keys(q.val());
        let values = Object.values(q.val());

        keys.map((k) => {
          return values.map((ele) => {
            ele.groupChatRoomID = k;
            ele.categoryID = cid;
            return ele;
          });
        });

        return setGroupChats(values);
      }
      return returnedValue;
    });
  //   ]);
  return returnedValue;
};
export default GetGroupChats;
