// import { makeAutoObservable } from "mobx";
// import { observer } from "mobx-react";
// import { create, persist } from "mobx-persist";

// // Model the application state.
// class Store {
//   userRole = "CU";

//   constructor() {
//     makeAutoObservable(this);
//   }

//   setUserRole = (value) => {
//     console.log("🚀 ~ file: index.js ~ line 13 ~ mobxStore ~ value", value);

//     this.userRole = value;
//   };
// }
// var mobxStore = new Store();

// // persist(store);
// const hydrate = create({
//   storage: localStorage, // or AsyncStorage in react-native.
//   // default: localStorage
//   jsonify: true, // if you use AsyncStorage, here shoud be true
//   // default: true
// });

// export const someStore = persist(mobxStore);
// hydrate("someone", someStore)
//   // post hydration
//   .then(() => {});

import { makeAutoObservable } from "mobx";
import {
  persistence,
  useClear,
  useDisposers,
  isSynchronized,
  StorageAdapter,
} from "mobx-persist-store";

function readStore(name) {
  return new Promise((resolve) => {
    const data = localStorage.getItem(name);
    resolve(JSON.parse(data));
  });
}

function writeStore(name, content) {
  return new Promise((resolve) => {
    localStorage.setItem(name, JSON.stringify(content));
    resolve();
  });
}

class RouteStore {
  counter = 0;
  userRole = "CU";

  setUserRole = (value) => {
    console.log("🚀 ~ file: index.js ~ line 13 ~ mobxStore ~ value", value);

    this.userRole = value;
  };

  constructor() {
    makeAutoObservable(this);
  }
}

export default persistence({
  name: "RouteStore",
  properties: ["userRole"],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore,
  }),
  reactionOptions: {
    // optional
    delay: 2000,
  },
})(new RouteStore());
