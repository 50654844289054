import firebase from "firebase";
import "firebase/database";
import _ from "lodash";
// import Store from "../../MobX/Store";
const GetChats = async (uid, setChatMessages) => {
  let returnedValue = null;
  //   await Promise.all([
  firebase
    .database()
    .ref("chats")
    .child("p2p")
    .orderByChild(uid)
    .equalTo(true)
    .on("value", async (q) => {
      if (q.exists()) {
        let keys = Object.keys(q.val());
        let values = Object.values(q.val());

        //   let unread = values.filter((u) => u.lastUnseenMessages > 0).length;
        //   if (unread > 0) Store.setUnread(unread);
        //   else Store.setUnread(null);
        let group = await Promise.all(
          keys.map(async (q, index) => {
            //   let partnerId = q.replace(uid, "");
            //   partnerId = partnerId.replace("_", "");

            if (uid === values[index].sentBy) {
              console.log("Values reced", values);
              let result = values.map((ele) =>
                Object.keys(ele).map((f) => {
                  if (f != uid && f.length > 20) {
                    return f;
                  } else {
                    return false;
                  }
                })
              );
              result = result.flat().filter(Boolean);

              var data;
              await firebase
                .database()
                .ref("users")
                .child(result[0])
                .once("value", (u) => (data = u.val()));
              let name = data.name;

              return {
                ...values[index],
                // partnerToken: data.expoToken ? data.expoToken : "",
                image: data.image,
                name: name,
                chatKey: q,
                // chatRoomId: q,
                // partnerId: partnerId,
                // type: data.type,
              };
            } else {
              var data;
              await firebase
                .database()
                .ref("users")
                .child(values[index].sentBy)
                .once("value", (u) => (data = u.val()));
              let name = data.name;

              return {
                ...values[index],
                // partnerToken: data.expoToken ? data.expoToken : "",
                image: data.image,
                name: name,
                chatKey: q,
                // chatRoomId: q,
                // partnerId: partnerId,
                // type: data.type,
              };
            }
          })
        );

        returnedValue = group;

        return setChatMessages(group);

        //   Store.setActiveChats(_.orderBy(group, (e) => e.timestamp, "desc"));
      }
      // else Store.setActiveChats([]);
      return returnedValue;
    });
  //   ]);
  return returnedValue;
};
export default GetChats;
