import firebase from "firebase";
import "firebase/database";
import _ from "lodash";
// import Store from "../../MobX/Store";
const GetUser = async (uid,setData) => {
  let returnedValue = null;
  //   await Promise.all([
  firebase
    .database()
    .ref("users")
    .child(uid)
    .on("value", async (q) => {
      if (q.exists()) {
        let keys = Object.keys(q.val());
        let values = Object.values(q.val());

        return setData(q.val());
      }
      return returnedValue;
    });
  //   ]);
  return returnedValue;
};
export default GetUser;
