import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Button,
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";

// Lodash
import _ from "lodash";

//Simple bar
import SimpleBar from "simplebar-react";

// firebase
import firebase from "firebase";

// moment
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Images
// import avatar1 from "../../assets/images/users/avatar-1.jpg";
// import avatar2 from "../../assets/images/users/avatar-2.jpg";
// import avatar3 from "../../assets/images/users/avatar-3.jpg";
// import avatar4 from "../../assets/images/users/avatar-4.jpg";
// import avatar6 from "../../assets/images/users/avatar-6.jpg";

// firebase queries

// get queries for P2P chat
import GetChats from "./GetUserChats";
import GetUserMessages from "./GetUserMessages";
import GetUser from "./GetUserData";

// creatipn queries for P2P chat
import SendP2PMessage from "./SendP2PMessage";

// Get Queries for Group Chat
import GetGroupChats from "./GetGroupChats";
import GetGroupChatMessages from "./GetGroupChatMessages";

// if (sessionStorage.getItem("authUser")) {
//   const obj = JSON.parse(sessionStorage.getItem("authUser"));
//   const uNm = obj.email.split("@")[0];
//   username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
// }

class Chat extends Component {
  constructor(props) {
    super(props);
    this.messagesEnd = React.createRef();
    this.state = {
      breadcrumbItems: [
        { title: "Godfidence", link: "#" },
        { title: "Chat", link: "#" },
      ],
      chats: [
        // {
        //   id: 1,
        //   status: "online",
        //   image: avatar2,
        //   name: "Steven Franklin",
        //   description: "Hey! there I'm available",
        //   time: "05 min",
        //   isActive: true,
        // },
        // {
        //   id: 2,
        //   status: "Intermediate",
        //   image: avatar3,
        //   name: "Adam Miller",
        //   description: "I've finished it! See you so",
        //   time: "12 min",
        //   isActive: false,
        // },
        // {
        //   id: 3,
        //   status: "online",
        //   image: avatar1,
        //   name: "Keith Gonzales",
        //   description: "This theme is awesome!",
        //   time: "24 min",
        //   isActive: false,
        // },
        // {
        //   id: 4,
        //   status: "offline",
        //   image: avatar4,
        //   name: "Jose Vickery",
        //   description: "Nice to meet you",
        //   time: "1 hr",
        //   isActive: false,
        // },
        // {
        //   id: 5,
        //   status: "Intermediate",
        //   image: "Null",
        //   name: "Mitchel Givens",
        //   description: "Hey! there I'm available",
        //   time: "3 hrs",
        //   isActive: false,
        // },
        // {
        //   id: 6,
        //   status: "online",
        //   image: avatar6,
        //   name: "Stephen Hadley",
        //   description: "I've finished it! See you so",
        //   time: "5 hrs",
        //   isActive: false,
        // },
        // {
        //   id: 7,
        //   status: "online",
        //   image: "Null",
        //   name: "Keith Gonzales",
        //   description: "This theme is awesome!",
        //   time: "24 min",
        //   isActive: false,
        // },
      ],
      groups: [
        { id: 1, image: "G", name: "General" },
        { id: 2, image: "R", name: "Reporting" },
        { id: 3, image: "M", name: "Meeting" },
        { id: 4, image: "A", name: "Project A" },
        { id: 5, image: "B", name: "Project B" },
      ],
      contacts: [
        // {
        //   //   category: "A",
        //   child: [
        //     { id: 1, name: "Adam Miller" },
        //     { id: 2, name: "Alfonso Fisher" },
        //   ],
        // },
        // {
        //   //   category: "B",
        //   child: [{ id: 3, name: "Bonnie Harney" }],
        // },
        // {
        //   //   category: "C",
        //   child: [
        //     { id: 4, name: "Charles Brown" },
        //     { id: 5, name: "Carmella Jones" },
        //     { id: 6, name: "Carrie Williams" },
        //   ],
        // },
        // {
        //   //   category: "D",
        //   child: [{ id: 7, name: "Dolores Minter" }],
        // },
      ],
      messages: [
        // {
        //   id: "34",
        //   isRight: false,
        //   image: avatar2,
        //   name: "Steven Franklin",
        //   message: "Hey! I am available",
        //   time: "12:09",
        // },
        // {
        //   id: "35",
        //   isRight: true,
        //   name: username,
        //   message: "Hi, How are you? What about our next meeting?",
        //   time: "10:02",
        // },
        // {
        //   id: "36",
        //   isRight: false,
        //   image: avatar2,
        //   name: "Steven Franklin",
        //   message: "Its around next August!",
        //   time: "10:00",
        // },
        // {
        //   id: "37",
        //   isRight: true,
        //   image: avatar2,
        //   name: username,
        //   message: "Good ! then i will available at that time.",
        //   time: "10:00",
        // },
      ],
      other1: false,
      other2: false,
      settings: false,
      settings2: false,
      activeTab: "1",
      Chat_Box_Username: "",
      // Chat_Box_Username2: username,
      Chat_Box_User_Status: "",
      Chat_Box_User_isActive: false,
      curMessage: "",

      // user chats
      userChats: [],
      userMessages: [],
      userId: null,
      chatRoomID: null,
      receiver: null,
      user: null,

      // group chats'
      groupChats: [],
      groupMessages: [],
      groupId: null, // groupId is the groupChatRoomID (single uid fulfills two purposes)

      // conditional renderring helpers
      isGroupChatOpen: false,
      isUserChatOpen: false,
    };
  }
  async componentDidMount() {
    // scroll to the bottom of an opened chat

    this.addScroll();

    let userId = localStorage.getItem("userId");
    if (userId) {
      this.setState({ userId: userId });

      // Getting user to display current user's image and name etc
      let userData = await GetUser(userId, (e) => this.setUserData(e));

      // Getting the contacts
      this.getContacts();

      // get P2P chats (prev chat history)
      let p2pChats = await GetChats(userId, (e) => this.setP2PChats(e));

      // Get Group chats
      let groupChats = await GetGroupChats(
        "MQWOXKUdg1YiTlcGVu7",
        this.state.userId,
        (e) => this.setGroupChats(e)
      );

      // send a group message
      // let group_message_create = await firebase
      //   .database()
      //   .ref("groupChatMessages")
      //   .child("-MQl9PPiY04QxycKh_7n")
      //   .push({
      //     sentBy: this.state.userId,
      //     timestamp: moment().unix(),
      //     message: "This is the message from first member usama ",
      //     media: "",
      //     isMedia: false,
      //   });
    }
  }

  // set user data
  setUserData = (value) => {
    this.setState({ user: value }, () =>
      console.log("User data ", this.state.user)
    );
  };

  // for chat history
  setP2PChats = (value) => {
    this.setState({ userChats: value });
  };

  // for chat details

  getUserMessages = async (roomID) => {
    // console.log(
    //   "🚀 ~ file: Chat.js ~ line 350 ~ Chat ~ getUserMessages= ~ roomID",
    //   roomID,
    //   this.state.userId
    // );
    let result = await GetUserMessages(
      roomID,
      (e) => this.setUserMessages(e),
      this.state.userId
    );
  };

  setUserMessages = (value) => {
    this.setState({ userMessages: value }, () => this.addScroll());
  };

  GenerateRoomId = (id, uid) => {
    if (id > uid) return `${uid}_${id}`;
    else return `${id}_${uid}`;
  };
  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  //Use For Chat Box
  UserChatOpen = async (id, name, status, image, chatKey, chat) => {
    if (chatKey) {
      /// this means chat already initated just update it
      this.setState({
        chatRoomID: chatKey,
        isGroupChatOpen: false,
        isUserChatOpen: true,
      });

      let chatModule = [...this.state?.userChats];

      for (let k = 0; k < this.state?.userChats?.length; k++) {
        chatModule[k].isActive = false;
      } // Enable All Option First
      chatModule[id - 1].isActive = true;

      await this.getUserMessages(chatKey);
      this.setState({
        Chat_Box_Username: name,
        Chat_Box_User_Status: status,
        // messages: msg,
        userChats: chatModule,
      });
    } else {
      this.setState({
        Chat_Box_Username: name,
        Chat_Box_User_Status: status,
        // messages: msg,
        userChats: [],
      });
    }
  };

  // when contact is clicked to itiate chat
  ContactOpenForChat = async (id, name, status, image, array, chatKey) => {
    this.setState({
      receiver: array.id,
      isGroupChatOpen: false,
      isUserChatOpen: true,
    });

    if (chatKey) {
      /// this means chat already initated just update it

      this.setState({ chatRoomID: chatKey });

      let chatModule = [...this.state?.userChats];

      for (let k = 0; k < this.state?.userChats?.length; k++) {
        chatModule[k].isActive = false;
      } // Enable All Option First
      chatModule[id - 1].isActive = true;

      await this.getUserMessages(chatKey);

      this.setState({
        Chat_Box_Username: name,
        Chat_Box_User_Status: status,
        // messages: msg,
        // userChats: chatModule,
      });
    } else {
      this.setState({
        Chat_Box_Username: name,
        Chat_Box_User_Status: status,
        // messages: msg,
        // userChats: [],
      });
    }
  };

  addP2PMessage = async () => {
    let media = "";
    let participant1 = this.state.userId;
    let participant2 = this.state.receiver;

    if (this.state.chatRoomID) {
      let sendMessageRestofTime = await SendP2PMessage(
        this.state.chatRoomID,
        this.state.userId,
        this.state.curMessage,
        media,
        false,
        participant1,
        participant2
      );
    } else {
      let sendMessageForFirstTime = await SendP2PMessage(
        null,
        this.state.userId,
        this.state.curMessage,
        media,
        false,
        participant1,
        participant2
      );
    }

    this.addScroll();
    this.setState({ curMessage: "" });

    // let d = new Date();
    // var n = d.getSeconds();
    // let demoMsg = this.state.messages;
    // demoMsg.push({
    //   isRight: true,
    //   name: this.state.Chat_Box_Username2,
    //   message: this.state.curMessage,
    //   time: "00:" + n,
    // });
    // this.setState({ messages: demoMsg, curMessage: "" });
  };

  addScroll = () => {
    // var elmnt = document.getElementById("chatDiv");
    // console.log("ELement captured ", elmnt)

    // elmnt.scrollTop = 100;
    if (this.messagesEnd.current) {
      console.log(
        "🚀 ~ file: Chat.js ~ line 446 ~ Chat ~ this.messagesEnd",
        this.messagesEnd
      );
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    }
  };

  // custom methods for firebase chat
  setGroupChats = (value) => {
    this.setState({ groupChats: value });
  };

  setGroupMessages = (value) => {
    this.setState({ groupMessages: value }, () =>
      console.log("messages group ====>", this.state.groupMessages)
    );
  };

  getContacts = async () => {
    var userContacts = await firebase.database().ref("users");
    userContacts.on("value", (snapshot) => {
      const data = snapshot.val();

      let contactsArray = [];
      let contactListObj = {
        category: "Z",
        child: _.mapValues(data, (value, key) => {
          return { id: key, ...value };
        }),
      };

      for (let item in data) {
        contactsArray.push(contactListObj.child[item]);
      }
      let customObj = {
        child: contactsArray,
      };

      this.setState({
        contacts: [...this.state.contacts, customObj],
      });
    });
  };

  // Group Chat methods
  GroupChatOpen = async (id, name, status, image, groupDetails) => {
    console.log(
      "🚀 ~ file: Chat.js ~ line 318 ~ Chat ~ id, name, status, image",
      groupDetails
    );
    this.setState({
      groupId: groupDetails?.groupChatRoomID,
      isGroupChatOpen: true,
      isUserChatOpen: false,
    });

    if (groupDetails?.groupChatRoomID) {
      /// this means chat already initated just update it
      this.setState({ groupChatRoomID: groupDetails?.groupChatRoomID });

      let chatModule = [...this.state?.groupChats];

      for (let k = 0; k < this.state.groupChats.length; k++) {
        chatModule[k].isActive = false;
      } // Enable All Option First
      chatModule[id - 1].isActive = true;

      // Get group chat messages here
      await this.getMessagesOfSingleGroup(groupDetails?.groupChatRoomID);

      this.setState({
        Chat_Box_Username: name,
        Chat_Box_User_Status: status,
        // messages: msg,
        // userChats: chatModule,
      });
    } else {
      this.setState({
        Chat_Box_Username: name,
        Chat_Box_User_Status: status,
        // messages: msg,
        // userChats: [],
      });
    }
  };

  // Get messages for a single group
  getMessagesOfSingleGroup = async (groupId) => {
    // Get Group messages
    let groupMessageDetails = await GetGroupChatMessages(groupId, (e) =>
      this.setGroupMessages(e)
    );
  };

  addGroupMessage = async () => {
    let media = "";
    let participant1 = this.state.userId;

    // groupId is the groupChatRoomID

    // if (this.state.groupId) {
    //   let sendMessageRestofTime = await SendP2PMessage(
    //     this.state.chatRoomID,
    //     this.state.userId,
    //     this.state.curMessage,
    //     media,
    //     false,
    //     participant1,
    //     participant2
    //   );
    // }

    // else {
    //   let sendMessageForFirstTime = await SendP2PMessage(
    //     null,
    //     this.state.userId,
    //     this.state.curMessage,
    //     media,
    //     false,
    //     participant1,
    //     participant2
    //   );
    // }

    this.addScroll();
    this.setState({ curMessage: "" });
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Chat"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <div className="d-lg-flex mb-4">
              <div className="chat-leftsidebar">
                <div className="p-3 border-bottom">
                  <Media>
                    <div className="align-self-center mr-3">
                      <img
                        src={this.state.user?.image}
                        className="avatar-xs rounded-circle"
                        alt=""
                      />
                    </div>
                    <Media body>
                      <h5 className="font-size-15 mt-0 mb-1">
                        {this.state?.user?.name}
                      </h5>
                      <p className="text-muted mb-0">
                        <i className="mdi mdi-circle text-success align-middle mr-1"></i>{" "}
                        Active
                      </p>
                    </Media>

                    <div>
                      <Dropdown
                        isOpen={this.state.other1}
                        toggle={() =>
                          this.setState({ other1: !this.state.other1 })
                        }
                        className="dropdown chat-noti-dropdown"
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn dropdown-toggle"
                          type="button"
                        >
                          <i className="mdi mdi-dots-horizontal font-size-20"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem href="#">Action</DropdownItem>
                          <DropdownItem href="#">Another action</DropdownItem>
                          <DropdownItem href="#">
                            Something else here
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Media>
                </div>
                <CardBody className="border-bottom py-2">
                  <div className="search-box chat-search-box">
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </CardBody>

                <div className="chat-leftsidebar-nav">
                  <Nav pills justified>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggleTab("1");
                        }}
                      >
                        <i className="ri-message-2-line font-size-20"></i>
                        <span className="mt-2 d-none d-sm-block">Chat</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        <i className="ri-group-line font-size-20"></i>
                        <span className="mt-2 d-none d-sm-block">Group</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggleTab("3");
                        }}
                      >
                        <i className="ri-contacts-book-2-line font-size-20"></i>
                        <span className="mt-2 d-none d-sm-block">Contacts</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={this.state.activeTab} className="py-4">
                  <TabPane tabId="1">
                    <div>
                      <h5 className="font-size-14 px-3 mb-3">Recent</h5>
                      <ul className="list-unstyled chat-list">
                        <SimpleBar style={{ maxHeight: "345px" }}>
                          {this.state.userChats?.map((chat, key) => (
                            <li
                              key={key}
                              className={chat?.isActive ? "active" : ""}
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  this.UserChatOpen(
                                    key + 1,
                                    chat?.name,
                                    chat?.status,
                                    chat?.image,
                                    chat?.chatKey,
                                    chat
                                  );
                                }}
                              >
                                <Media>
                                  {chat?.image === "Null" ? (
                                    <div
                                      className={
                                        chat?.status === "online"
                                          ? "user-img mr-3 online"
                                          : chat?.status !== "Intermediate"
                                          ? "user-img mr-3"
                                          : "user-img mr-3 away"
                                      }
                                    >
                                      <div className="avatar-xs align-self-center">
                                        <span className="avatar-title rounded-circle bg-light text-body">
                                          {chat?.name?.charAt(0)}
                                        </span>
                                        <span className="user-status"></span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      className={
                                        "user-img align-self-center mr-3 " +
                                        chat?.status
                                      }
                                    >
                                      <img
                                        src={chat?.image}
                                        className="rounded-circle avatar-xs"
                                        alt=""
                                      />
                                      <span className="user-status"></span>
                                    </div>
                                  )}

                                  <Media body className="overflow-hidden">
                                    <h5 className="text-truncate font-size-14 mb-1">
                                      {chat?.name}
                                    </h5>
                                    <p className="text-truncate mb-0">
                                      {chat?.lastMessage}
                                    </p>
                                  </Media>
                                  <div className="font-size-11">
                                    {moment
                                      .unix(chat?.timestamp)
                                      .format("DD-MM-YYYY")}
                                  </div>
                                </Media>
                              </Link>
                            </li>
                          ))}
                        </SimpleBar>
                      </ul>
                    </div>
                  </TabPane>

                  <TabPane tabId="2">
                    <h5 className="font-size-14 px-3 mb-3">Group</h5>
                    <ul className="list-unstyled chat-list">
                      <SimpleBar style={{ maxHeight: "345px" }}>
                        {this.state?.groupChats.map((group, key) => (
                          <li key={key}>
                            <Link
                              to="#"
                              onClick={() => {
                                this.GroupChatOpen(
                                  key + 1,
                                  group?.groupName,
                                  group?.status,
                                  group?.image,
                                  group
                                );
                              }}
                            >
                              <Media className="align-items-center">
                                <div className="avatar-xs mr-3">
                                  <span className="avatar-title rounded-circle bg-light text-body">
                                    {group?.groupName.substring(0, 1)}
                                  </span>
                                </div>

                                <Media body>
                                  <h5 className="font-size-14 mb-0">
                                    {group?.groupName}
                                  </h5>
                                  <p className="text-truncate mb-0">
                                    {group?.lastMessage}
                                  </p>
                                </Media>
                              </Media>
                            </Link>
                          </li>
                        ))}
                      </SimpleBar>
                    </ul>
                  </TabPane>

                  <TabPane tabId="3">
                    <h5 className="font-size-14 px-3 mb-3">Contact</h5>

                    <SimpleBar style={{ maxHeight: "345px" }}>
                      {this.state?.contacts?.map((contact, key) => (
                        <div key={key} className={key + 1 !== 1 ? "mt-4" : ""}>
                          <div className="p-3">{contact?.category}</div>

                          <ul className="list-unstyled chat-list">
                            {contact.child.map((array, key) => (
                              <li key={key}>
                                <Link
                                  to="#"
                                  onClick={() => {
                                    console.log("Contact ===>", array);
                                    this.ContactOpenForChat(
                                      array?.id,
                                      array?.name,
                                      array?.status,
                                      array?.image,
                                      array
                                    );
                                  }}
                                >
                                  <h5 className="font-size-14 mb-0">
                                    {array?.name}
                                  </h5>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </SimpleBar>
                  </TabPane>
                </TabContent>
              </div>

              <div className="w-100 user-chat mt-4 mt-sm-0">
                <div className="p-3 px-lg-4 user-chat-border">
                  <Row>
                    <Col md={4} xs={6}>
                      <h5 className="font-size-15 mb-1 text-truncate">
                        {this.state?.Chat_Box_Username}
                      </h5>
                      <p className="text-muted text-truncate mb-0">
                        {this.state?.Chat_Box_Username ? (
                          <i
                            className={
                              this.state?.Chat_Box_User_Status === "online"
                                ? "mdi mdi-circle text-success align-middle mr-1"
                                : this.state.Chat_Box_User_Status ===
                                  "Intermediate"
                                ? "mdi mdi-circle text-warning align-middle mr-1"
                                : "mdi mdi-circle align-middle mr-1"
                            }
                          ></i>
                        ) : null}
                        {this.state?.Chat_Box_User_Status}
                      </p>
                    </Col>
                    <Col md={8} xs={6}>
                      <ul className="list-inline user-chat-nav text-right mb-0">
                        <li className="list-inline-item d-inline-block d-sm-none">
                          <Dropdown
                            isOpen={this.state?.settings}
                            toggle={() =>
                              this.setState({ settings: !this.state?.settings })
                            }
                          >
                            <DropdownToggle
                              className="btn nav-btn dropdown-toggle"
                              type="button"
                            >
                              <i className="mdi mdi-magnify"></i>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-md">
                              <Form className="p-2">
                                <div className="search-box">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      className="form-control rounded"
                                      placeholder="Search..."
                                    />
                                    <i className="mdi mdi-magnify search-icon"></i>
                                  </div>
                                </div>
                              </Form>
                            </DropdownMenu>
                          </Dropdown>
                        </li>
                        <li className="d-none d-sm-inline-block">
                          <div className="search-box mr-2">
                            <div className="position-relative">
                              <Input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                              />
                              <i className="mdi mdi-magnify search-icon"></i>
                            </div>
                          </div>
                        </li>
                        <li className="list-inline-item m-0 d-none d-sm-inline-block">
                          <Dropdown
                            isOpen={this.state.settings2}
                            toggle={() =>
                              this.setState({
                                settings2: !this.state.settings2,
                              })
                            }
                          >
                            <DropdownToggle className="btn nav-btn" tag="i">
                              <i className="mdi mdi-cog"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem href="#">View Profile</DropdownItem>
                              <DropdownItem href="#">Clear chat</DropdownItem>
                              <DropdownItem href="#">Muted</DropdownItem>
                              <DropdownItem href="#">Delete</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </li>

                        <li className="list-inline-item">
                          <Dropdown
                            isOpen={this.state.other2}
                            toggle={() =>
                              this.setState({ other2: !this.state.other2 })
                            }
                          >
                            <DropdownToggle className="btn nav-btn " tag="i">
                              <i className="mdi mdi-dots-horizontal"></i>
                            </DropdownToggle>
                            <DropdownMenu right>
                              <DropdownItem href="#">Action</DropdownItem>
                              <DropdownItem href="#">
                                Another action
                              </DropdownItem>
                              <DropdownItem href="#">
                                Something else
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>

                {/* chat box for P2P conversation starts here */}
                {this.state.isUserChatOpen == true ? (
                  <>
                    <div className="px-lg-2">
                      <div className="chat-conversation p-3 chat-conversation-height">
                        <ul className="list-unstyled mb-0 pr-3">
                          <SimpleBar style={{ maxHeight: "450px" }}>
                            {this.state?.userMessages?.map(
                              (msgContainer, key) => {
                                return msgContainer?.data
                                  ?.slice(0)
                                  .reverse()
                                  .map((message, index) => (
                                    <li
                                      key={index}
                                      className={
                                        message?.sentBy == this.state.userId
                                          ? "right"
                                          : ""
                                      }
                                      style={{ paddingRight: "20px" }}
                                    >
                                      <div className="conversation-list">
                                        {message?.isRight === false ? (
                                          <div className="chat-avatar">
                                            <img src={message?.image} alt="" />
                                          </div>
                                        ) : null}

                                        <div className="ctext-wrap">
                                          {/* <div className="conversation-name">
                      {message?.name}
                    </div> */}
                                          <div className="ctext-wrap-content">
                                            <p className="mb-0">
                                              {message?.message}
                                            </p>
                                          </div>
                                          <p className="chat-time mb-0">
                                            <i className="mdi mdi-clock-outline align-middle mr-1"></i>{" "}
                                            {message?.time}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  ));
                              }
                            )}
                            <div
                              style={{
                                float: "left",
                                clear: "both",
                                background: "red",
                              }}
                              ref={(el) => {
                                this.messagesEnd = el;
                              }}
                            ></div>
                          </SimpleBar>
                        </ul>
                      </div>
                    </div>
                    <div className="px-lg-3">
                      <div className="p-3 chat-input-section ">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <Input
                                type="text"
                                value={this.state.curMessage}
                                onChange={(e) => {
                                  this.setState({ curMessage: e.target.value });
                                }}
                                className="form-control chat-input"
                                placeholder="Enter Message..."
                              />
                            </div>
                          </Col>
                          <Col xs={{ size: "auto" }}>
                            <Button
                              onClick={this.addP2PMessage}
                              type="submit"
                              color="primary"
                              className="chat-send w-md waves-effect waves-light"
                            >
                              <span className="d-none d-sm-inline-block mr-2">
                                Send
                              </span>{" "}
                              <i className="mdi mdi-send"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                ) : null}
                {/* chat box for group conversation starts here          */}
                {this.state.isGroupChatOpen ? (
                  <>
                    <div className="px-lg-2">
                      <div className="chat-conversation p-3 chat-conversation-height">
                        <ul className="list-unstyled mb-0 pr-3">
                          <SimpleBar style={{ maxHeight: "450px" }}>
                            {this.state?.groupMessages?.map(
                              (msgContainer, key) => {
                                return msgContainer?.data
                                  ?.slice(0)
                                  .reverse()
                                  .map((message, index) => (
                                    <li
                                      key={index}
                                      className={
                                        message?.sentBy == this.state.userId
                                          ? "right"
                                          : ""
                                      }
                                      style={{ paddingRight: "20px" }}
                                    >
                                      <div className="conversation-list">
                                        {message?.isRight === false ? (
                                          <div className="chat-avatar">
                                            <img src={message?.image} alt="" />
                                          </div>
                                        ) : null}

                                        <div className="ctext-wrap">
                                          {/* <div className="conversation-name">
                                      {message?.name}
                                    </div> */}
                                          <div className="ctext-wrap-content">
                                            <p className="mb-0">
                                              {message?.message}
                                            </p>
                                          </div>
                                          <p className="chat-time mb-0">
                                            <i className="mdi mdi-clock-outline align-middle mr-1"></i>{" "}
                                            {message?.time}
                                          </p>
                                        </div>
                                      </div>
                                    </li>
                                  ));
                              }
                            )}
                            <div
                              style={{
                                float: "left",
                                clear: "both",
                                background: "red",
                              }}
                              ref={(el) => {
                                this.messagesEnd = el;
                              }}
                            ></div>
                          </SimpleBar>
                        </ul>
                      </div>
                    </div>
                    <div className="px-lg-3">
                      <div className="p-3 chat-input-section ">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <Input
                                type="text"
                                value={this.state.curMessage}
                                onChange={(e) => {
                                  this.setState({ curMessage: e.target.value });
                                }}
                                className="form-control chat-input"
                                placeholder="Enter  Message..."
                              />
                            </div>
                          </Col>
                          <Col xs={{ size: "auto" }}>
                            <Button
                              onClick={this.addGroupMessage}
                              type="submit"
                              color="primary"
                              className="chat-send w-md waves-effect waves-light"
                            >
                              <span className="d-none d-sm-inline-block mr-2">
                                Send
                              </span>{" "}
                              <i className="mdi mdi-send"></i>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default Chat;
