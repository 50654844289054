import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

import { baseURL } from "../config/API";
import mobxStore from "../mobxStore/index";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};
firebase.initializeApp(firebaseConfig);

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    // if (firebaseConfig) {
    // Initialize Firebase
    // firebase.initializeApp(firebaseConfig);
    firebase.auth().onIdTokenChanged(async (user) => {
      if (user) {
        // let token = this.getUser();
        //
        let token = await user.getIdTokenResult(true);
        let { claims } = token || {};
        console.log(
          "🚀 ~ file: firebase_helper.js ~ line 38 ~ FirebaseAuthBackend ~ firebase.auth ~ claims",
          claims
        );
        if (claims?.firstTimeLogin == false) {
          // window.location.href = "/dashboard";
        }

        localStorage.setItem("authUser", JSON.stringify(user));
      } else {
        localStorage.removeItem("authUser");
      }
    });
    // }
  }

  /**
   * Registers the user with given details
   */
  registerUser = async (email, password, username, userRole) => {
    console.log(
      "🚀 ~ file: firebase_helper.js ~ line 29 ~ FirebaseAuthBackend ~ registerUser= ~ email, password, username,userRole",
      email,
      password,
      username,
      userRole
    );
    try {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then(
            async (user) => {
              resolve(firebase.auth().currentUser);

              // get user's token after registeration
              let token = await firebase.auth().currentUser.getIdToken();
              let body = {
                uid: user?.user?.uid,
                idToken: token,
                firstTimeLogin: true,
              };
              switch (userRole) {
                case "CU":
                  body.isCustomer = true;
                  break;
                case "WL":
                  body.isWorkshopLeader = true;
                  break;
                case "GA":
                  body.isGroupAdmin = true;
                  break;

                default:
                  break;
              }

              let registerUserInRealTimeDB = await firebase
                .database()
                .ref("users/" + user?.user?.uid)
                .set({
                  name: username,
                  email: user?.user?.email,
                });
              let axiosConfig = {
                headers: {
                  "Content-Type": "application/json;charset=UTF-8",
                  "Access-Control-Allow-Origin": "*",
                },
              };
              // set the category of user in his/her custom claim
              // POST request using fetch()
              fetch(`${baseURL}/customClaim/create`, {
                // Adding method type
                method: "POST",

                // Adding body or contents to send
                body: JSON.stringify(body),

                // Adding headers to the request
                headers: {
                  "Content-type": "application/json; charset=UTF-8",
                  "Access-Control-Allow-Origin": "*",
                },
              })
                .then((result) => {
                  const { uid, idToken, ...rest } = body;
                  // return someStore.setUserRole(rest);
                  return mobxStore.setUserRole(rest);
                })
                .catch((err) => console.log(err));
            },
            (error) => {
              reject(this._handleError(error));
            }
          );
      });
    } catch (error) {
      console.log(
        "🚀 ~ file: firebase_helper.js ~ line 81 ~ FirebaseAuthBackend ~ registerUser= ~ error",
        error
      );
    }
  };

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          (user) => {
            console.log(
              "🚀 ~ file: firebase_helper.js ~ line 140 ~ FirebaseAuthBackend ~ returnnewPromise ~ user",
              user
            );
            localStorage.setItem("userId", user?.user?.uid);

            resolve(firebase.auth().currentUser);
          },
          (error) => {
            reject(this._handleError(error));
          }
        );
    });
  };

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  setLoggeedInUser = (user) => {
    localStorage.setItem("authUser", JSON.stringify(user));
  };

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null;
    return JSON.parse(localStorage.getItem("authUser"));
  };

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    console.log(
      "🚀 ~ file: firebase_helper.js ~ line 212 ~ FirebaseAuthBackend ~ _handleError ~ error",
      error
    );
    // var errorCode = error.code;
    var errorMessage = error.message;
    return errorMessage;
  }
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
export const initFirebaseBackend = (config) => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config);
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
export const getFirebaseBackend = () => {
  return _fireBaseBackend;
};
