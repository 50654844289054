import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  FormGroup,
  Progress,
  Container,
} from "reactstrap";
import _ from "lodash";
import firebase from "firebase";

import classnames from "classnames";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { countryCodes } from "../../helpers/countryCodeList";

// using redux
import { setLoading } from "../../store/actions";

import { baseURL } from "../../config/API";

import mobxStore from "../../mobxStore/index";

class GroupAdminWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Forms", link: "#" },
        { title: "Form Wizard", link: "#" },
      ],
      uid: null,
      activeTab: 1,
      activeTabProgress: 1,
      progressValue: 25,
      firstName: "",
      lastName: "",
      phone: "",
      country: "Country",
      city: "",
      postalCode: "",
      state: "",
      dob: "",
      selectedCategory: "",
      allCategories: {},
      allQuestions: [],
      appointmentDate: "",

      // alerts
      success_msg: false,
      confirm_msg: false,
      success_dlg: false,
      error_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
    };
  }
  componentDidMount() {
    let userData = JSON.parse(localStorage.getItem("authUser"));

    if (userData) {
      this.setState({ uid: userData?.uid });
    }
    firebase
      .firestore()
      .collection("userCategories")
      .doc("awyfEOWiJwxYRuzT1ReK")
      .onSnapshot((doc) => {
        this.setState({ allCategories: doc?.data() });
      });

    // Get all questions

    firebase
      .firestore()
      .collection("Questions")
      .doc("IzoJ8rLrGn9zIpaQiiip")
      .onSnapshot((doc) => {
        let questions = [];
        for (let property in doc?.data()) {
          // console.log(`${property}: ${allCategories[property]}`);
          let customobj = {
            name: property,
            value: doc?.data()[property],
            answer: "",
          };
          if (
            property === "q1" ||
            property === "q2" ||
            property === "q3" ||
            property === "q11"
          ) {
            customobj.answer = "Yes";
          }
          questions.push(customobj);
        }
        this.setState({ allQuestions: questions });
      });
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  };

  toggleTabProgress = (tab) => {
    if (this.state.activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        this.setState({
          activeTabProgress: tab,
        });

        if (tab === 1) {
          this.setState({ progressValue: 25 });
        }
        if (tab === 2) {
          this.setState({ progressValue: 50 });
        }
        if (tab === 3) {
          this.setState({ progressValue: 75 });
        }
        if (tab === 4) {
          this.setState({ progressValue: 100 });
        }
      }
    }
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  selectCountry = (event) => {
    this.setState({ country: event.target.value });
  };
  selectCategory = (event) => {
    this.setState({ selectedCategory: event.target.value });
  };

  // Define the method directly in your class
  searchCityAndState = _.debounce(async (e) => {
    try {
      var client = new XMLHttpRequest();
      client.open(
        "GET",
        `http://api.zippopotam.us/${this.state.country}/${this.state.postalCode}`,
        true
      );
      client.send();

      client.onreadystatechange = () => {
        if (client.readyState == 4) {
          if (client?.response) {
            let response = JSON.parse(client?.response);

            let { places } = response || {};

            if (places) {
              // client.responseText
              this.setState({
                state: places[0]?.["state abbreviation"],
                city: places[0]?.["place name"],
              });
            } else {
              this.setState({
                state: "",
                city: "",
              });
            }
          }
        }
      };
    } catch (error) {
      console.log("🚀  error", error?.response);
    }
  }, 2000);
  validateForm = () => {
    const {
      firstName,
      lastName,
      phone,
      country,
      postalCode,
      city,
      state,
      dob,
      selectedCategory,
    } = this.state;
    if (
      this.state.activeTabProgress === 1 &&
      (firstName == "" ||
        lastName == "" ||
        phone == "" ||
        country == "Country" ||
        postalCode == "" ||
        city == "" ||
        state == "" ||
        dob == "" ||
        selectedCategory == "")
    ) {
      this.setState({
        error_dlg: true,
        dynamic_description: "Fill all required fields",
      });
      return false;
    } else {
      // this.setState({ success_dlg: true });

      return true;
    }
  };

  onAppointmentDateChange = (e) => {
    this.setState({ appointmentDate: new Date() });
  };
  handleSubmit = async () => {
    this.props.setLoading(true);

    const {
      firstName,
      lastName,
      phone,
      postalCode,
      city,
      state,
      country,
      selectedCategory,
      allQuestions,
      allCategories,
    } = this.state;

    // creating the group name
    let personName = firstName + " " + lastName;

    var matches = personName.match(/\b(\w)/g); // ['J','S','O','N']
    var acronym = matches.join(""); // JSON

    let groupName = null;
    for (let property in this.state?.allCategories) {
      // console.log(`${property}: ${allCategories[property]}`);

      if (property === this.state.selectedCategory) {
        groupName = allCategories[property] + "_" + acronym;
      }
    }

    try {
      // Add a new document in collection "Users"
      let saveUserData = await firebase
        .firestore()
        .collection("Users")
        .doc(this.state.uid)
        .set({
          name: firstName + " " + lastName,
          state: state,
          country: country,
          city: city,
          phone: phone,
          postalCode: postalCode,
          category: selectedCategory,
          allQuestions: allQuestions,
        });

      // add data in real time db as well
      // firstly create a group under chats node (chats-> categoryID -> groupID->content)
      let chatRef = firebase
        .database()
        .ref(`chats/group/${this.state.selectedCategory}`)
        .push({
          sentBy: this.state.uid,
          timestamp: moment().unix(),
          lastMessage: "",
          media: "",
          isMedia: false,
          [this.state.uid]: true,
          groupName: groupName,
          admin: this.state.uid,
        });
      let groupID = (await chatRef).key;

      // create group record in firestore to future comparisions
      let createGroupRecordInFirestore = await firebase
        .firestore()
        .collection("groupCategories")
        .doc(this.state.selectedCategory)
        .update({
          [`${groupID}`]: {
            memberCount: 1,
            state: {
              [this.state.state]: 1,
            },
          },
          updatedAt: moment().unix(),
        });

      let userCustomClaim = JSON.parse(
        JSON.parse(localStorage.getItem("RouteStore"))
      );

      let { userRole } = userCustomClaim;
      userRole.firstTimeLogin = false;
      userRole.uid = this.state.uid;

      fetch(`${baseURL}/customClaim/firstTimeLogin/update`, {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify(userRole),

        // Adding headers to the request
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then(async (result) => {
          // return someStore.setUserRole(rest);

          await firebase.auth().currentUser?.getIdTokenResult(true);
          mobxStore.setUserRole(userRole);
          this.props.setLoading(false);
          this.props.history.push("/dashboard");
        })

        .catch((err) => console.log(err));
    } catch (error) {
      this.props.setLoading(false);
    }
  };
  render() {
    let { allCategories } = this.state;

    let userCategories = [];
    for (let property in this.state?.allCategories) {
      // console.log(`${property}: ${allCategories[property]}`);
      let customobj = {
        name: property,
        value: allCategories[property],
      };
      userCategories.push(customobj);
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            <Breadcrumbs
              title="Form Wizard"
              breadcrumbItems={this.state.breadcrumbItems}
            />
            {this.state.success_dlg ? (
              <SweetAlert
                success
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ success_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            {this.state.error_dlg ? (
              <SweetAlert
                error
                title={this.state.dynamic_title}
                onConfirm={() => this.setState({ error_dlg: false })}
              >
                {this.state.dynamic_description}
              </SweetAlert>
            ) : null}

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title mb-4">Setup Wizard</h4>
                    <div></div>

                    <div id="progrss-wizard" className="twitter-bs-wizard">
                      <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 1,
                            })}
                            // onClick={() => {
                            //   this.toggleTabProgress(1);
                            // }}
                          >
                            <span className="step-number">01</span>
                            <span className="step-title">Personal Details</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 2,
                            })}
                            // onClick={() => {
                            //   this.toggleTabProgress(2);
                            // }}
                          >
                            <span className="step-number">02</span>
                            <span className="step-title">G Unit Details</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 3,
                            })}
                            // onClick={() => {
                            //   this.toggleTabProgress(3);
                            // }}
                          >
                            <span className="step-number">03</span>
                            <span className="step-title">Optional Details</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: this.state.activeTabProgress === 4,
                            })}
                            // onClick={() => {
                            //   this.toggleTabProgress(4);
                            // }}
                          >
                            <span className="step-number">04</span>
                            <span className="step-title">Select a Slot</span>
                          </NavLink>
                        </NavItem>
                      </ul>

                      <div id="bar" className="mt-4">
                        <Progress
                          color="success"
                          striped
                          animated
                          value={this.state.progressValue}
                        />
                      </div>
                      <TabContent
                        activeTab={this.state.activeTabProgress}
                        className="twitter-bs-wizard-tab-content"
                      >
                        <TabPane tabId={1}>
                          <Form>
                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-firstname-input14">
                                    First name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-firstname-input14"
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-lastname-input15">
                                    Last name
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-lastname-input15"
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <Row>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-phoneno-input16">
                                    Phone
                                  </Label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-phoneno-input16"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="6">
                                <FormGroup>
                                  <Label for="basicpill-email-input17">
                                    Country
                                  </Label>
                                  <Input
                                    type="select"
                                    name="country"
                                    id="exampleSelectMulti"
                                    onChange={(val) => this.selectCountry(val)}
                                  >
                                    {countryCodes?.map((ele, index) => (
                                      <option key={index} value={ele.code}>
                                        {ele?.name}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <Label for="basicpill-address-input2">
                                    Postal Code
                                  </Label>
                                  <Input
                                    style={{
                                      cursor:
                                        this.state.country == "Country"
                                          ? "not-allowed"
                                          : "pointer",
                                      backgroundColor:
                                        this.state.country == "Country"
                                          ? "#F1F5F7"
                                          : "#FFFF",
                                    }}
                                    disabled={
                                      this.state.country == "Country"
                                        ? true
                                        : false
                                    }
                                    name="postalCode"
                                    value={this.state.postalCode}
                                    onChange={(event) =>
                                      this.setState(
                                        { postalCode: event.target.value },
                                        () => this.searchCityAndState()
                                      )
                                    }
                                    className="form-control"
                                    id="basicpill-email-input17"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <Label for="basicpill-address-input2">
                                    City
                                  </Label>
                                  <Input
                                    style={{
                                      cursor: "not-allowed",
                                      backgroundColor: "#F1F5F7",
                                    }}
                                    disabled={true}
                                    name="city"
                                    value={this.state.city}
                                    className="form-control"
                                    id="basicpill-email-input17"
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <Label for="basicpill-address-input2">
                                    State
                                  </Label>
                                  <Input
                                    style={{
                                      cursor: "not-allowed",
                                      backgroundColor: "#F1F5F7",
                                    }}
                                    disabled={true}
                                    type="text"
                                    name="state"
                                    value={this.state.state}
                                    className="form-control"
                                    id="basicpill-email-input17"
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <Label for="basicpill-address-input2">
                                    Date of Birth
                                  </Label>
                                  <Input
                                    type="date"
                                    name="dob"
                                    value={this.state.dob}
                                    className="form-control"
                                    id="basicpill-email-input17"
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <Label for="basicpill-email-input17">
                                    Category
                                  </Label>
                                  <Input
                                    type="select"
                                    name="selectedCategory"
                                    id="exampleSelectMulti"
                                    onChange={(val) => this.selectCategory(val)}
                                  >
                                    {userCategories?.map((ele, index) => (
                                      <option key={index} value={ele.name}>
                                        {ele?.value}
                                      </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q1") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <Input
                                      type="select"
                                      name="q1"
                                      id="exampleSelectMulti"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q1") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });

                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      <option key={1} value="Yes">
                                        Yes
                                      </option>
                                      <option key={2} value="No">
                                        No
                                      </option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q2") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <Input
                                      type="select"
                                      name="q2"
                                      id="exampleSelectMulti"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q2") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      <option key={1} value="Yes">
                                        Yes
                                      </option>
                                      <option key={2} value="No">
                                        No
                                      </option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q3") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <Input
                                      type="select"
                                      name="q3"
                                      id="exampleSelectMulti"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q3") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      <option key={1} value="Yes">
                                        Yes
                                      </option>
                                      <option key={2} value="No">
                                        No
                                      </option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <Form>
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q4") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <textarea
                                      row="2"
                                      name="q4"
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q4") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q5") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <textarea
                                      row="2"
                                      name="q4"
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q5") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q6") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <textarea
                                      row="2"
                                      name="q6"
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q6") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q7") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <textarea
                                      row="2"
                                      name="q7"
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q7") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q8") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <textarea
                                      row="2"
                                      name="q7"
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q8") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q9") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <textarea
                                      row="2"
                                      name="q9"
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q9") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q10") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <textarea
                                      row="2"
                                      name="q10"
                                      id="basicpill-address-input1"
                                      className="form-control"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q10") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    ></textarea>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                              <Row>
                                <Col lg="12">
                                  <FormGroup>
                                    <Label for="basicpill-email-input17">
                                      {this.state.allQuestions?.map((ele) => {
                                        if (ele.name === "q11") {
                                          return ele.value;
                                        }
                                      })}
                                    </Label>
                                    <Input
                                      type="select"
                                      name="q11"
                                      id="exampleSelectMulti"
                                      onChange={(event) => {
                                        return this.state.allQuestions?.map(
                                          (ele) => {
                                            if (ele.name === "q11") {
                                              ele.answer = event.target.value;
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            } else {
                                              this.setState({
                                                [this.state.allQuestions]: this
                                                  .state.allQuestions,
                                              });
                                              return ele;
                                            }
                                          }
                                        );
                                      }}
                                    >
                                      <option key={1} value="Yes">
                                        Yes
                                      </option>
                                      <option key={2} value="No">
                                        No
                                      </option>
                                    </Input>
                                  </FormGroup>
                                </Col>
                              </Row>{" "}
                            </Form>
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div className="row justify-content-center">
                            <Col lg="6">
                              <div className="text-center">
                                <div className="mb-4">
                                  {/* <i className="mdi mdi-check-circle-outline text-success display-4"></i> */}
                                  <h3 className="text-muted">
                                    Appointment Date and Time
                                  </h3>
                                </div>
                                <div>
                                  <DateTimePicker
                                    onChange={
                                      this.onAppointmentDateChange
                                      // this.setState({
                                      //   appointment: e.target.value,
                                      // })
                                    }
                                    value={this.state.appointmentDate}
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        </TabPane>
                      </TabContent>
                      <ul className="pager wizard twitter-bs-wizard-pager-link">
                        <li
                          className={
                            this.state.activeTabProgress === 1
                              ? "previous disabled"
                              : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              this.toggleTabProgress(
                                this.state.activeTabProgress - 1
                              );
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        {this.state.activeTabProgress === 4 ? (
                          <li
                            // className={
                            //   this.state.activeTabProgress === 4
                            //     ? "next disabled"
                            //     : "next"
                            // }
                            className="next"
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                if (this.validateForm()) {
                                  this.handleSubmit();
                                }
                              }}
                            >
                              Submit
                            </Link>
                          </li>
                        ) : (
                          <li
                            className={
                              this.state.activeTabProgress === 4
                                ? "next disabled"
                                : "next"
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => {
                                if (this.validateForm()) {
                                  this.toggleTabProgress(
                                    this.state.activeTabProgress + 1
                                  );
                                }
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// export default GroupAdminWizard;
export default withRouter(connect(null, { setLoading })(GroupAdminWizard));
