import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  SET_USER_ROLE,
} from "./actionTypes";

export const registerUser = (user) => {
  return {
    type: REGISTER_USER,
    payload: { user },
  };
};

export const registerUserSuccessful = (user) => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: user,
  };
};

export const registerUserFailed = (error) => {
  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  };
};
export const setUserRole = (role) => {
  return {
    type: SET_USER_ROLE,
    payload: role,
  };
};
