import React, { Component } from "react";
import { Row, Col, Card, CardBody, ButtonGroup, Button } from "reactstrap";
import generate from "project-name-generator";
// firebase
import firebase from "firebase";
import "firebase/firestore";
//Import Charts
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";

let randomRoom = generate().dashed;
class RevenueAnalytics extends Component {
  state = {
    series: [
      {
        name: "2020",
        type: "column",
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
      },
      {
        name: "2019",
        type: "line",
        data: [23, 32, 27, 38, 27, 32, 27, 38, 22, 31, 21, 16],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 3],
        curve: "smooth",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: false,
      },
      colors: ["#5664d2", "#1cbb8c"],
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      // states to manage iframes
      broadcastStarted: false,
      viewBroadcast: false,
      meetingUrl: "",
    },
  };

  updateFirebaseMeetingURL = async () => {
    this.setState(
      {
        broadcastStarted: !this.state.broadcastStarted,
        viewBroadcast: false,
        meetingUrl: `https://jitsi.dumbexample.com/${randomRoom}`,
      },
      () => {
        let url = this.state.meetingUrl;
        console.log(
          "🚀 ~ file: RevenueAnalytics.js ~ line 78 ~ RevenueAnalytics ~ updateFirebaseMeetingURL= ~ url",
          this.state.meetingUrl
        );

        firebase
          .firestore()
          .collection("meetings")
          .doc("1234567890")
          .update({ url: this.state.meetingUrl });
      }
    );
  };
  viewOnGoingBroadcast = () => {
    firebase
      .firestore()
      .collection("meetings")
      .doc("1234567890")
      .onSnapshot((snap) => {
        console.log("Get the meetings ==== > ", snap.data());
        this.setState({
          viewBroadcast: !this.state.viewBroadcast,
          broadcastStarted: false,
          meetingUrl: snap.data().url,
        });
      });
  };
  render() {
    return (
      <React.Fragment>
        <Card>
          {/* This component is used to switch the iframe to broadcast */}

          {this.state.viewBroadcast == true ? (
            <CardBody>
              <div>
                <div id="line-column-chart" className="apex-charts" dir="ltr">
                  {/* <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="280" /> */}

                  <iframe
                    allow="microphone;camera"
                    title="viewBroadcast"
                    style={{ height: "80vh", width: "100%" }}
                    src={this.state.meetingUrl}
                  ></iframe>
                </div>
              </div>
            </CardBody>
          ) : null}
          {this.state.broadcastStarted == true ? (
            <CardBody>
              <div>
                <div id="line-column-chart" className="apex-charts" dir="ltr">
                  {/* <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="280" /> */}

                  <iframe
                    title="broadcast"
                    allow="microphone;camera"
                    style={{ height: "80vh", width: "100%" }}
                    src={`https://jitsi.dumbexample.com/${randomRoom}`}
                  ></iframe>
                </div>
              </div>
            </CardBody>
          ) : (
            <CardBody>
              <div className="float-right d-none d-md-inline-block"></div>
              <h4 className="card-title mb-4">Broadcast Controls</h4>
              <div
                style={{
                  height: "50vh",
                  backgroundColor: "#222831",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div id="line-column-chart" className="apex-charts" dir="ltr">
                  {/* <ReactApexChart options={this.state.options} series={this.state.series} type="line" height="280" /> */}

                  <div>
                    <Button
                      size="lg"
                      style={{ backgroundColor: "#ef4f4f", marginRight: 5 }}
                      type="button"
                      onClick={() => this.updateFirebaseMeetingURL()}
                    >
                      Start Broadcast
                    </Button>
                    <Button
                      size="lg"
                      style={{ backgroundColor: "#00587a" }}
                      type="button"
                      onClick={() => this.viewOnGoingBroadcast()}
                    >
                      View Broadcast
                    </Button>
                  </div>
                </div>
              </div>
            </CardBody>
          )}
          {/* <CardBody className="border-top text-center">
            <Row>
              <Col sm={4}>
                <div className="d-inline-flex">
                  <h5 className="mr-2">$12,253</h5>
                  <div className="text-success">
                    <i className="mdi mdi-menu-up font-size-14"> </i>2.2 %
                  </div>
                </div>
                <p className="text-muted text-truncate mb-0">This month</p>
              </Col>

              <Col sm={4}>
                <div className="mt-4 mt-sm-0">
                  <p className="mb-2 text-muted text-truncate">
                    <i className="mdi mdi-circle text-primary font-size-10 mr-1"></i>{" "}
                    This Year :
                  </p>
                  <div className="d-inline-flex">
                    <h5 className="mb-0 mr-2">$ 34,254</h5>
                    <div className="text-success">
                      <i className="mdi mdi-menu-up font-size-14"> </i>2.1 %
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm={4}>
                <div className="mt-4 mt-sm-0">
                  <p className="mb-2 text-muted text-truncate">
                    <i className="mdi mdi-circle text-success font-size-10 mr-1"></i>{" "}
                    Previous Year :
                  </p>
                  <div className="d-inline-flex">
                    <h5 className="mb-0">$ 32,695</h5>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        */}
        </Card>
      </React.Fragment>
    );
  }
}

export default RevenueAnalytics;
