import React, { Component } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Redirect,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

import firebase from "firebase";
import Loading from "react-fullscreen-loading";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";

// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./theme.scss";

//Fake backend
import fakeBackend from "./helpers/AuthType/fakeBackend";

//Firebase helper
import { initFirebaseBackend } from "./helpers/firebase_helper";

import { baseURL } from "./config/API";

import mobxStore from "./mobxStore/index";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
initFirebaseBackend();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    // let token = await firebase.auth().currentUser?.getIdTokenResult();
    // console.log("Goood Token,", token?.claims);

    // setTimeout(async () => {
    //   // let token = this.getUser();
    //   // let { claims } = token || {};
    //   let user = await firebase.auth().currentUser?.getIdTokenResult();
    //   console.log(
    //     "🚀 ~ file: App.js ~ line 66 ~ App ~ setTimeout ~ token?.claims",
    //     user
    //   );
    // }, 2000);
    let userData = JSON.parse(JSON.parse(localStorage.getItem("RouteStore")));

    if (mobxStore.userRole?.firstTimeLogin == true) {
      if (userData?.userRole?.isCustomer) {
        this.props.history.push("/setup-wizard-customer");
      }
      if (userData?.userRole?.isGroupAdmin) {
        this.props.history.push("/setup-wizard-admin");
      }
      if (userData?.userRole?.isWorkshopLeader) {
        this.props.history.push("/setup-wizard-leader");
      }
    }

    // POST request using fetch()
    // fetch(`${baseURL}/customClaim/get/${userData?.uid}`, {
    //   // Adding method type
    //   method: "GET",

    //   // Adding body or contents to send
    //   // body: JSON.stringify(body),

    //   // Adding headers to the request
    //   headers: {
    //     "Content-type": "application/json; charset=UTF-8",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // })
    //   .then((userRecord) => userRecord.json())
    //   .then((response) => {
    //     console.log(
    //       "🚀 ~ file: App.js ~ line 70 ~ App ~ .then ~ response",
    //       response
    //     );
    //     const { customClaims } = response?.result || {};
    //     console.log(
    //       "🚀 ~ file: App.js ~ line 71 ~ App ~ .then ~ customCliams",
    //       customClaims
    //     );
    //     if (customClaims?.isCustomer) {
    //       return (
    //         <Redirect
    //           to={{
    //             pathname: "/setup-widard-customer",
    //             state: { from: this.props.location },
    //           }}
    //         />
    //       );
    //     } else if (customClaims?.isGroupAdmin == true) {
    //       return (
    //         <Redirect
    //           to={{
    //             pathname: "/setup-wizard-admin",
    //             state: { from: this.props.location },
    //           }}
    //         />
    //       );
    //     } else if (customClaims?.isWorkshopLeader) {
    //       return (
    //         <Redirect
    //           to={{
    //             pathname: "/setup-wizard-leader",
    //             state: { from: this.props.location },
    //           }}
    //         />
    //       );
    //     } else {
    //       this.props.history.push("/chat");
    //     }
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  }
  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();

    return (
      <React.Fragment>
        <Loading loading={this.props?.layout?.loading} loaderColor="#3498db" />
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default withRouter(connect(mapStateToProps, null)(App));
