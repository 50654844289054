import firebase from "firebase";
import "firebase/database";
import "firebase/firestore";
import _ from "lodash";
import moment from "moment";
const titleDate = (timestamp) => {
  let c_date = moment();
  let incoming_date = moment(timestamp, "MM/DD/YYYY");
  if (moment(c_date).diff(incoming_date, "days") === 0) return "Today";
  else if (moment(c_date).diff(incoming_date, "days") === 1) return "Yesterday";
  else if (moment(c_date).diff(incoming_date, "days") < 7)
    return moment(incoming_date).format("dddd");
  else return moment(incoming_date).format("MM/DD/YYYY");
};
const GetGroupChatMessages = (groupChatRoomId, setData, userId, setLastKey) => {
  console.log(
    "🚀 ~ file: GetGroupChatMessages.js ~ line 16 ~ GetGroupChatMessages ~ chatRoomId",
    groupChatRoomId
  );
  firebase
    .database()
    .ref("groupChatMessages")
    .child(groupChatRoomId)
    .orderByKey()
    .limitToLast(25)
    .on("value", (q) => {
      if (q.exists()) {
        let key = q.val();
        let key_data = _.mapValues(key, (value, key) => {
          return { key: key, ...value };
        });

        // setLastKey(_.first(Object.keys(key_data)));
        let arr = Object.values(q.val());
        console.log(
          "🚀 ~ file: GetGroupChatMessages.js ~ line 32 ~ .on ~ arr",
          arr
        );

        let group = _.groupBy(arr, (e) =>
          moment.unix(e.timestamp).format("MM/DD/YYYY")
        );
        let keys = Object.keys(group);
        let values = Object.values(group);
        let p_data = keys.map((q, index) => ({
          title: titleDate(q),
          // data: values[index],
          data: _.reverse(values[index]),
        }));
        // setData(_.reverse(p_data));
        setData(p_data);
      } else {
        console.log("null returned");
      }
    });
};
export default GetGroupChatMessages;
