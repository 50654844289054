import firebase from "firebase";
import "firebase/database";
import "firebase/firestore";
import moment from "moment";
const SendP2PMessage = async (
  chatRoomId,
  uid,
  message,
  media,
  isMedia,
  participant1,
  participant2
) => {
  if (chatRoomId) {
    // let u_self = await firebase
    //   .database()
    //   .ref("chats")
    //   .child("p2p")
    //   .update({
    //     [chatRoomId]: {
    //       sentBy: uid,
    //       timestamp: moment().unix(),
    //       lastMessage: message,
    //       media: media,
    //       isMedia: isMedia,
    //       [participant1]: true,
    //       [participant2]: true,
    //     },
    //   });

    let chatRef = firebase.database().ref("chats/p2p").child(chatRoomId);
    var data = {
      sentBy: uid,
      timestamp: moment().unix(),
      lastMessage: message,
      media: media,
      isMedia: isMedia,
      [participant1]: true,
      [participant2]: true,
    };

    let u_room_create = await chatRef.update(data);
    //   var counter = 0;
    //   let r_partner = await firebase
    //     .database()
    //     .ref("userChats")
    //     .child(id)
    //     .child(chatRoomId)
    //     .once("value", (q) => {
    //       if (q.exists()) counter = (q.val()?.lastUnseenMessages || 0) + 1;
    //       else counter = 1;
    //     });
    let u_message_create = await firebase
      .database()
      .ref("userChatMessages")
      .child(chatRoomId)
      .push({
        sentBy: uid,
        timestamp: moment().unix(),
        message: message,
        media: media,
        isMedia: isMedia,
      });
    //   let u_partner = await firebase
    //     .database()
    //     .ref("chats")
    //     .child("p2p")
    //     .update({
    //       [chatRoomId]: {
    //         sentBy: uid,
    //         timestamp: moment().unix(),
    //         lastMessage: message,
    //         media: media,
    //         isMedia: isMedia,
    //         participant1,
    //         participant2,
    //       },
    //     });
    await Promise.all([u_room_create, u_message_create]);
  } else {
    // create a messages node for p2p

    let chat_room_id_key = await firebase
      .database()
      .ref("userChatMessages")
      .push().key;
    let chat_room_id_ref = await firebase
      .database()
      .ref("userChatMessages" + `/${chat_room_id_key}`);

    let u_send_message = await chat_room_id_ref.push({
      isMedia: false,
      message: message,
      media: media,
      sentBy: uid,
      timestamp: moment().unix(),
      updated: true,
    });

    // create user chats nodes for previous chats
    // p2p chats

    let chatRef = firebase.database().ref("chats/p2p").child(chat_room_id_key);
    var data = {
      isMedia: isMedia,
      lastMessage: message,
      media: media,
      sentBy: uid,
      timestamp: moment().unix(),
      updated: true,
      [participant1]: true,
      [participant2]: true,
    };
    // var updates = {};
    // updates["/p2p" + "/VkJ0c5e09QVKROahI9E4YHDvcFf1"] = data;

    let u_room_create = await chatRef.update(data);

    await Promise.all([u_send_message, u_room_create]);
  }
};
export default SendP2PMessage;
